import { Button, Heading, HStack, VStack } from '@chakra-ui/react';
import Image from 'next/image';
import { tnoop, useTranslation } from 'utils/i18n/i18n';
import React from 'react';
import { getServerSidePropsFunc } from 'utils/i18n/server';
import { useRouter } from 'next/router';
import { TopMenu } from 'components/topMenu/TopMenu';
import { LogOutIcon } from 'icons';
import { DIVIDER } from 'components/ui/ActionMenu';
import useLoggedInUser from 'hooks/useLoggedInUser';
import { getDisplayName } from 'components/topMenu/UserAvatar';
import { withNoNavigation } from 'components/topMenu/Navigation';
import clayCirclesPic from '../../public/graphics/clayCircles.png';

const Custom404 = () => {
  const { t } = useTranslation();
  const { back } = useRouter();
  const { user, handleLogOut } = useLoggedInUser();

  const topMenuActions = [
    {
      name: user ? getDisplayName(user) : '',
      actions: [
        DIVIDER,
        { name: tnoop('common:actions.logOut'), icon: <LogOutIcon />, onClick: handleLogOut },
      ],
    },
  ];

  return (
    <TopMenu user={user} hideUser={!user} actions={topMenuActions} showLogo={true}>
      <HStack
        justifyContent="center"
        alignItems="center"
        spacing="md"
        paddingX="100px"
        height="75%"
      >
        <VStack spacing="lg" alignItems="flex-start" width="650px">
          <Heading fontWeight="600" fontSize="h1">
            {t('common:404.title')}
          </Heading>
          <Heading fontWeight="600" fontSize="h2">
            {t('common:404.description')}
          </Heading>
          <HStack alignItems="flex-start" justifyContent="flex-start" width="100%">
            <Button onClick={back} size="lg">
              {t('common:500.goBack')}
            </Button>
          </HStack>
        </VStack>
        <VStack width="100%">
          <Image src={clayCirclesPic} />
        </VStack>
      </HStack>
    </TopMenu>
  );
};

export default withNoNavigation(Custom404);
export const getStaticProps = getServerSidePropsFunc(['common']); // https://github.com/vercel/next.js/discussions/11945#discussioncomment-5397
